import { Title, Container, Main } from '../../components'
import styles from './styles.module.css'
import MetaTags from 'react-meta-tags'

const Technologies = () => {
  
  return <Main>
    <MetaTags>
      <title>О проекте</title>
      <meta name="description" content="Фудграм - Технологии" />
      <meta property="og:title" content="О проекте" />
    </MetaTags>
    
    <Container>
      <h1 className={styles.title}>Технологии</h1>
      <div className={styles.content}>
        <div>
          <h2 className={styles.subtitle}>Технологии, которые применены в этом проекте:</h2>
          <div className={styles.text}>
            <ul className={styles.textItem}>
              <li className={styles.textItem}>
                Язык программирования — <b>Python</b>
              </li>
              <li className={styles.textItem}>
                Веб фреймворк — <b>Django</b>
              </li>
              <li className={styles.textItem}>
                Набор инструментов для создания веб-сервисов и API на основе фреймворка Django — <b>Django REST framework</b>
              </li>
              <li className={styles.textItem}>
                Пакет для аутентификации с помощью токенов — <b>Djoser</b>
              </li>
              <li className={styles.textItem}>
              Объектно-реляционная СУБД — <b>PostgreSQL</b>
              </li>
              <li className={styles.textItem}>
              ПО для автоматизации развёртывания и управления приложениями в средах с поддержкой контейнеризации, контейнеризатор приложений — <b>Docker</b>
              </li>
              <li className={styles.textItem}>
              Инструмент для определения и запуска многоконтейнерных Docker приложений — <b>Docker-Compose</b>
              </li>
              <li className={styles.textItem}>
              Системы интеграции и доставки кода (CI/CD) — <b>GitHub Actions</b>
              </li>
              <li className={styles.textItem}>
              Веб-сервер и почтовый прокси-сервер — <b>Nginx</b>
              </li>
              <li className={styles.textItem}>
              Автономный веб-сервер — <b>Gunicorn</b>
              </li>
              <li className={styles.textItem}>
              Библиотека, позволяющая создавать PDF-документы — <b>Reportlib</b>
              </li>
              <li className={styles.textItem}>
              и др.
              </li>
            </ul>
          </div>
        </div>
      </div>
      
    </Container>
  </Main>
}

export default Technologies

